import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import { createIntl } from "@formatjs/intl/lib/src/create-intl";
import { IntlConfig, IntlShape } from "@formatjs/intl/lib/src/types";
import React, { createContext, useContext, useMemo } from "react";

import { DEFAULT_LOCALE_KEY } from "../consts/DEFAULT_LOCALE";

import { createFormatMessage } from "./createFormatMessage";
import { buildLocale } from "./utils";

type Store = {
  localeKey: ILocaleKey | undefined;
  currencyFormatLocale: string;
  shouldPassThrough: boolean;
  intl: IntlShape<string>;
  intlForCurrency: IntlShape<string>;
};

export type StoreProps = Partial<Omit<Store, "intl" | "intlForCurrency">>;

const getFormatMessage = (
  localeKey: ILocaleKey | undefined,
  formatMessage: IntlShape<string>["formatMessage"]
) => {
  if (
    localeKey?.language === Language.STRING_ID ||
    localeKey?.language === Language.GIBBERISH
  ) {
    return createFormatMessage(localeKey, formatMessage);
  }
  return formatMessage;
};

const buildIntl = ({
  localeKey,
  messages,
}: {
  localeKey: ILocaleKey | undefined;
  messages: IntlConfig<string>["messages"];
}): IntlShape<string> => {
  const locale = buildLocale(localeKey ?? DEFAULT_LOCALE_KEY);
  const intl = createIntl({
    locale: locale,
    defaultLocale: locale,
    messages: messages,
  });
  const formatMessage = getFormatMessage(localeKey, intl.formatMessage);
  return {
    ...intl,
    formatMessage,
  };
};

const DEFAULT_INTL = buildIntl({
  localeKey: DEFAULT_LOCALE_KEY,
  messages: {},
});

const DEFAULT_STORE: Store = {
  intl: buildIntl({
    localeKey: DEFAULT_LOCALE_KEY,
    messages: {},
  }),
  shouldPassThrough: false,
  localeKey: DEFAULT_LOCALE_KEY,
  currencyFormatLocale: DEFAULT_INTL.locale,
  intlForCurrency: DEFAULT_INTL,
};

const LocalizationContext = createContext<Store>(DEFAULT_STORE);

export const LocalizationProvider: React.FC<
  React.PropsWithChildren<StoreProps>
> = ({ children, localeKey, currencyFormatLocale, shouldPassThrough }) => {
  const initialValue = useMemo(() => {
    const value: Store = {
      ...DEFAULT_STORE,
    };
    value.localeKey =
      localeKey ??
      getGlobalProperty<ILocaleKey>("localeKey") ??
      value.localeKey;

    value.shouldPassThrough = shouldPassThrough ?? value.shouldPassThrough;

    value.intl = value.localeKey
      ? buildIntl({ localeKey: value.localeKey, messages: {} })
      : value.intl;

    value.currencyFormatLocale =
      currencyFormatLocale ||
      getGlobalProperty<string>("currencyFormatLocale") ||
      value.currencyFormatLocale ||
      value.intl.locale;

    value.intlForCurrency =
      value.currencyFormatLocale !== value.intl.locale
        ? createIntl({
            locale: value.currencyFormatLocale,
            messages: {},
          })
        : value.intl;
    return value;
  }, [currencyFormatLocale, localeKey, shouldPassThrough]);

  return (
    <LocalizationContext.Provider value={initialValue}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => {
  return useContext(LocalizationContext);
};
