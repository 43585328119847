import { useLocalization } from "@faire/web--source/common/localization/useLocalization";
import { useCallback } from "react";

import { logError } from "../logging";

import { localNum } from "./localNum";

export const useLocalNum = (): { localNum: typeof localNum } => {
  const { currencyFormatLocale } = useLocalization();
  const localNumHook = useCallback(
    (value: number, opts?: Intl.NumberFormatOptions): string | undefined => {
      if (value === undefined) {
        return undefined;
      }

      try {
        return Intl.NumberFormat(currencyFormatLocale, opts).format(value);
      } catch (error) {
        logError(
          `Error formatting number in useLocalNum. error: ${error}, currencyFormatLocale: ${currencyFormatLocale}, value: ${value}, opts: ${opts}`
        );
        return undefined;
      }
    },
    [currencyFormatLocale]
  ) as typeof localNum;
  return { localNum: localNumHook };
};
